.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  z-index: 100;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  margin: 0;
  padding: 0;
  pointer-events: auto;
  letter-spacing: normal;

  &__nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    min-height: 100%;
    z-index: 100;


    &_checkbox {
      display: none!important;
    }

    // animated icon
    &_icon {
      position: relative;
      margin-top: 3rem;
      transition: all .3s;
      display: none;

      &,
      &::before,
      &::after {
        width: 3rem;
        height: 2px;
        background-color: $color-nav-link;
        display: inline-block;
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 0;
        transition: all .2s;
      }

      &::before {
        top: -.8rem;
      }

      &::after {
        top: .8rem;
      }
    }

    &_button {
      background-color: transparent;
      cursor: pointer;
      height: 3rem;
      width: 3rem;
      position: fixed;
      top: -.6rem;
      right: 1rem;
      border-radius: 50%;
      z-index: 120;
      text-align: center;
      display: none;
    }

    &_menu {
      display: flex;
      margin-right: 0;

      & li {
        list-style-type: none;
        padding: 1.2rem 0;
        font-size: .85em;

        & a {
          color: $color-nav-link;
          padding: 0 2rem;
          text-decoration: none;
          text-transform: uppercase;
          z-index: 105;
          overflow: hidden;
          height: 1.5em;


          & span {
            position: relative;
            display: inline-block;
            -webkit-transition: -webkit-transform 0.3s;
            -moz-transition: -moz-transform 0.3s;
            transition: transform 0.3s;

            &::before {
              position: absolute;
              top: 100%;
              content: attr(data-hover);
              font-weight: 600;
              -webkit-transform: translate3d(0,0,0);
              -moz-transform: translate3d(0,0,0);
              transform: translate3d(0,0,0);
            }
          }

          &:hover span,
          &:focus span {
            -webkit-transform: translateY(-100%);
            -moz-transform: translateY(-100%);
            transform: translateY(-100%);
          }
        }
      }
    }

    &_link {
      display: block;
    }
  }

  &__logo-container {
    position: fixed;
    top: 1rem;
    left: 1rem;
    width: 3rem;
    height: 3rem;
    color: $color-nav-link;

    @include mq(mobile-lg) {
      top: .6rem;
      width: 4rem;
      height: 4rem;
    }
  }

  &__background {
    background-color: rgba($color-light, 0.8);
    -webkit-transition: background-color 0.5s cubic-bezier(0.28, 0.11, 0.32, 1);
    transition: background-color 0.5s cubic-bezier(0.28, 0.11, 0.32, 1);
    -webkit-transition-property: background-color, -webkit-backdrop-filter;
    transition-property: background-color, backdrop-filter;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
  }

  &__content {
    margin: 0 auto;
    padding: 0 2rem;
    position: relative;
    z-index: 102;
  }



  @include mq(mobile-md) {

    &__nav_checkbox:checked ~ &__content > &__nav_menu {
      display: block;
      padding: 3rem 0;
    }

    &__nav {
      &_button {
        display: block;
      }
      &_menu {
        display: none;
      }
    }
  }
}
