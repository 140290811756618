$color-blue: #059cca;
$color-light-blue: #04b3e9;
$color-dark-blue-2: #345a63;
$color-dark-blue: #0482a8;
$color-green: #039c43;
$color-green2: #03bd51;
$color-green-light: #01fc6a;
$color-gray: #777;
$color-dark-gray: #666;
$color-dark-gray-1: #777;
$color-dark-gray-2: #333;
$color-bg-dark: #333;
$color-light: #fff;
$color-black: #000;
$color-light-gray: #f7f7f7;
$color-light-gray-2: #eee;
$color-light-gray-3: #bbb;
$color-yellow: #ffb900;
$color-orange: #ff7730;
$color-purple: #5643fa;
$color-red: #f00202;
$color-plastic: #d8e2dc;
$color-plastic-dark: #b1b9b4;
$color-nav-link: #839c9b;
$color-skills-bg-1: #00a7ff;
$color-skills-bg-2: #00c302;


$default-font-size: 1.6rem;

$grid-width: 114rem;