a[data-tooltip] {
  position: relative;
}

*[data-tooltip]::after {
  content: attr(data-tooltip);
  display: block;
  position: absolute;
  background-color: rgba(16, 16, 16, 0.7);
  padding: 1em 2em;
  color: #dcdcdc;
  font-size: .8em;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  border-radius: 4px;
  transform: scale(0);
  transition:
          transform ease-out 150ms,
          bottom ease-out 150ms;
}

*[data-tooltip]:hover::after {
  transform: scale(1);
  bottom: 100%;
}

.text-center {
  text-align: center;
}

.push {
  margin-left: auto;
}
