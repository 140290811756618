.skills {

  &__content {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10rem 15rem 10rem;

    @include mq(mobile-md) {
      padding: 10rem 5rem 10rem;
    }

    &-card {
      height: 18rem;
      width: 18rem;
      margin: 3rem;
      padding: 4rem;
      text-align: center;
      background: $color-light;
      color: $color-light;
      border-radius: 4px;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      box-shadow: 0 .5rem 3rem 0 rgba(0, 0, 0, 0.1);
      transition: all ease-in .5s;
      background: transparent;


      @include mq(mobile-lg) {
        height: 15rem;
        width: 15rem;
        margin: 1rem;
        padding: 3rem;
      }

      @include mq(mobile-md) {
        height: 14rem;
        width: 14rem;
        padding: 2rem;
      }

      @include mq(mobile-sm) {
        height: 12rem;
        width: 12rem;
      }

      &:hover {
        transform: scale(1.05);
        box-shadow: 0 .6rem 3rem 0 rgba(0, 0, 0, 0.15);
      }
    }

    &-icon {
      width: 6rem;

      @include mq(mobile-lg) {
        width: 5rem;
      }

      @include mq(mobile-md) {
        width: 4rem;
      }
    }
  }
}

.skills-page {
  background-image: linear-gradient(
                  to right,
                  rgba(#3dc7ff, 0.9),
                  rgba(#30ff89, 0.9),
                  rgba(#3dc7ff, 0.9));
  background-size: 80%;
  animation: animate2 4s linear infinite;
}
