.progressbar {
  background-color: #ddd;
  border-radius: 50%;
  display: inline-block;
  height: 15rem;
  margin: 2rem;
  position: relative;
  width: 15rem;

  &__link {
    color: $color-plastic;
    transition: color ease-out .4s;

    &:hover,
    &:active {
      color: $color-plastic-dark;
    }
  }

  &__icon {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    &-container {
      width: 6rem;
      height: 6rem;
      position: relative;
    }

    & path {
      width: 100%;
      height: 100%;
    }
  }

  &__body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    backface-visibility: hidden;
    padding: 1rem;
    cursor: pointer;

    & img {
      max-width: 8rem;
    }

    &:hover {

    }
  }

  &:before {
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    content: '';
    display: flex;
    font-size: 3rem;
    justify-content: center;
    position: absolute;
    left: 1.2rem;
    right: 1.2rem;
    top: 1.2rem;
    bottom: 1.2rem;
  }

  &:after {
    background-color: $color-green2;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 100%;
    width: 100%;
  }
  &[data-progress="0"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(90deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="1"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(93.6deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="2"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(97.2deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="3"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(100.8deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="4"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(104.4deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="5"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(108deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="6"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(111.6deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="7"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(115.2deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="8"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(118.8deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="9"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(122.4deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="10"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(126deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="11"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(129.6deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="12"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(133.2deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="13"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(136.8deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="14"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(140.4deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="15"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(144deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="16"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(147.6deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="17"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(151.2deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="18"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(154.8deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="19"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(158.4deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="20"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(162deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="21"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(165.60000000000002deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="22"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(169.2deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="23"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(172.8deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="24"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(176.4deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="25"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(180deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="26"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(183.60000000000002deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="27"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(187.2deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="28"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(190.8deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="29"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(194.4deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="30"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(198deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="31"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(201.60000000000002deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="32"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(205.2deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="33"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(208.8deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="34"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(212.4deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="35"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(216deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="36"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(219.6deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="37"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(223.20000000000002deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="38"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(226.8deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="39"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(230.4deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="40"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(234deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="41"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(237.6deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="42"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(241.20000000000002deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="43"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(244.8deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="44"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(248.4deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="45"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(252deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="46"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(255.6deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="47"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(259.20000000000005deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="48"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(262.8deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="49"]:after {
    background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(266.4deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="50"]:after {
    background-image: linear-gradient(-90deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="51"]:after {
    background-image: linear-gradient(-86.4deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="52"]:after {
    background-image: linear-gradient(-82.8deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="53"]:after {
    background-image: linear-gradient(-79.2deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="54"]:after {
    background-image: linear-gradient(-75.6deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="55"]:after {
    background-image: linear-gradient(-72deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="56"]:after {
    background-image: linear-gradient(-68.4deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="57"]:after {
    background-image: linear-gradient(-64.8deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="58"]:after {
    background-image: linear-gradient(-61.2deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="59"]:after {
    background-image: linear-gradient(-57.6deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="60"]:after {
    background-image: linear-gradient(-54deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="61"]:after {
    background-image: linear-gradient(-50.4deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="62"]:after {
    background-image: linear-gradient(-46.8deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="63"]:after {
    background-image: linear-gradient(-43.199999999999996deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="64"]:after {
    background-image: linear-gradient(-39.6deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="65"]:after {
    background-image: linear-gradient(-36deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="66"]:after {
    background-image: linear-gradient(-32.4deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="67"]:after {
    background-image: linear-gradient(-28.799999999999997deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="68"]:after {
    background-image: linear-gradient(-25.200000000000003deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="69"]:after {
    background-image: linear-gradient(-21.599999999999994deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="70"]:after {
    background-image: linear-gradient(-18deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="71"]:after {
    background-image: linear-gradient(-14.399999999999991deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="72"]:after {
    background-image: linear-gradient(-10.799999999999997deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="73"]:after {
    background-image: linear-gradient(-7.200000000000003deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="74"]:after {
    background-image: linear-gradient(-3.599999999999994deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="75"]:after {
    background-image: linear-gradient(0deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="76"]:after {
    background-image: linear-gradient(3.600000000000009deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="77"]:after {
    background-image: linear-gradient(7.200000000000003deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="78"]:after {
    background-image: linear-gradient(10.799999999999997deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="79"]:after {
    background-image: linear-gradient(14.400000000000006deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="80"]:after {
    background-image: linear-gradient(18deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="81"]:after {
    background-image: linear-gradient(21.60000000000001deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="82"]:after {
    background-image: linear-gradient(25.200000000000003deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="83"]:after {
    background-image: linear-gradient(28.799999999999997deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="84"]:after {
    background-image: linear-gradient(32.400000000000006deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="85"]:after {
    background-image: linear-gradient(36deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="86"]:after {
    background-image: linear-gradient(39.599999999999994deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="87"]:after {
    background-image: linear-gradient(43.20000000000002deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="88"]:after {
    background-image: linear-gradient(46.80000000000001deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="89"]:after {
    background-image: linear-gradient(50.400000000000006deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="90"]:after {
    background-image: linear-gradient(54deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="91"]:after {
    background-image: linear-gradient(57.599999999999994deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="92"]:after {
    background-image: linear-gradient(61.20000000000002deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="93"]:after {
    background-image: linear-gradient(64.80000000000001deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="94"]:after {
    background-image: linear-gradient(68.4deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="95"]:after {
    background-image: linear-gradient(72deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="96"]:after {
    background-image: linear-gradient(75.6deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="97"]:after {
    background-image: linear-gradient(79.20000000000002deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="98"]:after {
    background-image: linear-gradient(82.80000000000001deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="99"]:after {
    background-image: linear-gradient(86.4deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
  &[data-progress="100"]:after {
    background-image: linear-gradient(90deg, $color-green2 50%, transparent 50%, transparent), linear-gradient(270deg, $color-green2 50%, #ddd 50%, #ddd);
  }
}


