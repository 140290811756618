.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: .5;
  overflow: hidden;

  &__content {
    width: 100%;
    height: auto;
    @supports (object-fit: cover) {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  }
}