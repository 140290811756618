.certificates {
  width: 100%;
  padding: 15rem 0;
  display: grid;
  justify-items: center;
  background-image: linear-gradient(
                  to right bottom,
                  rgba($color-blue, 0.8),
                  rgba($color-green, 0.8)),
                  url(../../../public/img/certificates_bg.jpg);
  background-size: cover;

  &__container {
    padding: 0 2rem;
    box-sizing: border-box;
  }

  &__header {
    text-align: center;
    padding-bottom: 10rem;

    &--text {
      font-size: 3em;
      color: $color-plastic;
      font-weight: 300;
    }
  }


  &__content {
    display: grid;
    display: -ms-flexbox;
    -ms-flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-items: center;
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;

    @include mq(desktop-md) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include mq(mobile-lg) {
      grid-template-columns: 1fr 1fr;
    }

    @include mq(mobile-md) {
      grid-template-columns: 1fr;
    }
  }


  &__block {
    min-width: 23rem;
    -ms-flex: 0 1 auto;
    padding: 1rem;
  }

  &__thumb {
    position: relative;
    display: inline-block;
    overflow: hidden;
    min-width: 23rem;
    max-width: 33rem;
    width: 100%;
    color: $color-plastic-dark;
    text-align: left;
    background: $color-light;
    border-radius: 2px;
    cursor: pointer;
    box-shadow: 3px 3px 12px 3px rgba(0, 0, 0, 0.3);
  }

  &__description {
    font-size: .7em;
    padding-bottom: 1rem;
    border-bottom: solid 1px rgba($color-plastic-dark, .4);
    margin-bottom: 1rem;
    opacity: 0;
  }

  &__thumb *,
  &__thumb:before,
  &__thumb:after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }

  &__thumb img {
    max-width: 100%;
    backface-visibility: hidden;
    vertical-align: top;
  }

  &__thumb:before,
  &__thumb:after {
    position: absolute;
    top: 2rem;
    right: 2rem;
    content: '';
    background-color: $color-plastic-dark;
    z-index: 1;
    opacity: 0;
  }

  &__thumb:before {
    width: 0;
    height: 1px;
  }

  &__thumb:after {
    height: 0;
    width: 1px;
  }

  &__thumb figcaption {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 1.5rem 2rem;
  }

  &__thumb-header {
    margin: 0;
    font-weight: normal;
    opacity: 0;
    font-size: .7em;
    text-transform: uppercase;
  }

  &__thumb:hover img,
  &__thumb.hover img {
    zoom: 1;
    filter: alpha(opacity=0);
    opacity: 0;
  }

  &__thumb:hover:before,
  &__thumb.hover:before,
  &__thumb:hover:after,
  &__thumb.hover:after {
    opacity: 1;
    -webkit-transition-delay: 0.25s;
    transition-delay: 0.25s;
  }

  &__thumb:hover:before,
  &__thumb.hover:before {
    width: 3rem;
  }

  &__thumb:hover:after,
  &__thumb.hover:after {
    height: 3rem;
  }

  &__thumb:hover &__thumb-header {
    -webkit-transition-delay: 0.35s;
    transition-delay: 0.35s;
    opacity: 1;
  }

  &__thumb:hover &__description {
    transition: opacity 1s;
    opacity: 1;
  }


}
