.works {
  padding: 15rem 0;
  display: grid;
  justify-items: center;


  @media (-moz-touch-enabled: 1), (pointer:coarse) {
    display: block;
  }

  &__header {
    text-align: center;
    padding-bottom: 4rem;
    &--text {
      font-size: 3em;
      font-weight: 300;
      color: $color-plastic-dark;
    }
  }

  &__content {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
    transition: all ease-out .5s;
    padding: 9.4%;




    @include mq(mobile-lg) {
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: justify;
    }

    @media (-moz-touch-enabled: 1), (pointer:coarse) {
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: justify;
    }
  }

  &__card {
    overflow: hidden;
    color: $color-plastic;
    margin: 3.8rem;
    box-shadow: 0 2rem 3rem rgba(0,0,0,.1);
    line-height: 0;
    text-decoration: none;
    position: relative;
    border-radius: 4px;
    width: 30rem;
    max-height: 32rem;
    transition: all ease-out .5s;
    background: $color-light;

    @include mq(desktop-sm) {
      margin: 2rem;
    }

    & img {
      width: 100%;
      object-fit: cover;
      transition: .5s;
    }

    &-header {
      text-align: center;
      padding-top: 2rem;
    }

    & .works__name {
      font-size: 1em;
      display: block;
      text-align: center;
      padding: 1rem;
      color: $color-plastic-dark;
      line-height: 2.6;
    }

    &-box {
      position: absolute;
      top: 0;
      left: 0;
      padding: 1rem;
      box-sizing: border-box;
      opacity: 0;
      width: 100%;
      height: 100%;
    }

    &-icon-container {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      width: 100%;
      height: 90%;
      padding: 1rem;
    }

    &-icon {
      width: 5rem;
      margin: 1rem;
    }


    &:hover {
      transform: scale(1.2);
      transition: all ease-out .5s;

      @include mq(desktop-sm) {
        transform: scale(1.1);
      }
    }
    &:hover > img {
      opacity: 0;
    }

    &:hover &-box {
      transition: opacity 1s;
      -webkit-transition-delay: 0.35s;
      transition-delay: 0.35s;
      opacity: 1;

    }
  }
}