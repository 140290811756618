.footer {
  display: grid;
  display: -ms-flexbox;
  -ms-flex-pack: center;
  justify-items: center;
  border-top: solid 1px $color-plastic;

  &__content {
    padding: 2rem;
    display: block;
    color: $color-plastic
  }
}