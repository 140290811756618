.skills {
  padding: 15rem 0;
  display: grid;
  justify-items: center;

  &__container {
    width: 90vw;
    padding: 3rem;
  }

  &__header {
    display: grid;
    justify-items: center;
    padding: 5rem 0;

    &--text {
      text-align: center;
      font-size: 3em;
      font-weight: 300;
      color: $color-dark-gray-1;
    }
  }

  &__active {
    padding: 4rem 0;
  }

  &__body {
    display: grid;
    justify-items: center;
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;

    @include mq(desktop-md) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include mq(mobile-lg) {
      grid-template-columns: 1fr 1fr;
    }

    @include mq(mobile-md) {
      grid-template-columns: 1fr;
    }
  }

  &__block {
    border: 1px solid transparent;
    padding: 2rem;
    transition: all .5s;

    &:hover {
      border: 1px solid $color-light-gray-3;
      border-radius: 4px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
      transform: scale(1.15);

      @include mq(desktop-sm) {
        transform: scale(1);
      }
    }
  }

  &__category {
    text-align: center;

    &_btn {
      display: inline-block;
      width: fit-content;
      color: $color-dark-gray;
      letter-spacing: 0;
      font-weight: 300;
      font-size: 1.2em;
      cursor: pointer;
      margin: .5rem;
      transition: all .5s ease-in-out;

      &:hover,
      &:active {
        letter-spacing: .5rem;
        color: $color-nav-link;
      }

      &:after,
      &:before {
        backface-visibility: hidden;
        border: 1px solid rgba($color-nav-link, 0);
        bottom: 0;
        content: " ";
        display: block;
        margin: 0 auto;
        position: relative;
        transition: all .5s ease-in-out;
        width: 0;
      }

      &:hover:after,
      &:hover:before {
        backface-visibility: hidden;
        border-color: $color-plastic-dark;
        transition: width .5s ease-in-out;
        width: 100%;
      }

      &:hover:before {
        bottom: auto;
        top: 0;
        width: 100%;
      }
    }

    @include mq(mobile-md) {
      font-weight: 400;
      font-size: .9em;
    }
  }
}