.chart {

  &__block {
    position: relative;
    text-align: center;
    color: $color-plastic;
    cursor: pointer;
  }
}






