@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes moveInTop {
  0% {
    opacity: 0;
    transform: translateY(-10rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(10rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes appearAndZoom {
  0% {
    opacity: 0;
    transform: scale(.5);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes animate {
  0% {
    background-position: -400%;
  }
  100% {
    background-position: 400%;
  }
}

@keyframes animate2 {
  0% {
    background-position: -400;
  }
  100% {
    background-position: 400%;
  }
}

@keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: $color-light;
  }
}

@-moz-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: $color-light;
  }
}

@-webkit-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: $color-light;
  }
}

@-ms-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: $color-light;
  }
}

@-o-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: $color-light;
  }
}