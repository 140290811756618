body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: $default-font-size;
  line-height: 1.7;
  color: $color-gray;
}

.upper {
  text-transform: uppercase;
}

.h1 {
  font-size: 4.5rem;
}

.h2 {
  font-size: 3.5rem;
  font-weight: 300;
}

.h3 {
  font-size: 2.5rem;
  font-weight: 300;
  margin-bottom: 1.2rem;
}

.h4 {
  font-size: 1.8rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

.h5 {
  font-size: 1.5rem;
}

.h6 {
  font-size: 1.4rem;
}

.text-header {
  display: inline-block;
  color: $color-green;
}

.paragraph {
  font-size: $default-font-size;
}

@include mq(mobile-sm) {
  .h1 {
    font-size: 2.5rem;
  }

  .h2 {
    font-size: 2rem;
    font-weight: 400;
  }

  .h3 {
    font-size: 2.5rem;
    font-weight: 300;
    margin-bottom: 1.2rem;
  }

  .h4 {
    font-size: 1.8rem;
    font-weight: 400;
    margin-bottom: 1rem;
  }

  .h5 {
    font-size: 1.5rem;
  }

  .h6 {
    font-size: 1.4rem;
  }
}